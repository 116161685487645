import { useFetcher } from "~/remix";
import { useRootLoaderData } from "~/root";
import { useSlugLoaderData } from "~/routes/game/$slug";

export const useBrowserSetTheme = () => {
  const sumbitter = useFetcher<{ theme: string | undefined }>();
  const setTheme = (theme: string, slug?: string) => {
    // const cookieName = slug && slug.length > 0 ? `theme-${slug}` : 'theme'
    sumbitter.submit({
      theme,
      ...(slug ? { slug } : undefined),
      intent: 'setThemeCookie',
    }, {
      action: '/',
      method: 'PATCH',
      navigate: false,
      preventScrollReset: true,
    })
    return theme;
  }

  return {
    setTheme,
    theme: sumbitter.data?.theme
  }
}


export default function useThemes(
) {
  const { availableThemes, theme } = useRootLoaderData();
  const {
    availableThemes: slug_availableThemes = undefined,
    slug = undefined,
    slugTheme
  } = { ...useSlugLoaderData() }

  const { setTheme, theme: themeBrowser } = useBrowserSetTheme();

  return {
    setTheme,
    theme,
    availableThemes: slug_availableThemes ?? availableThemes,
    slug,
    slugTheme,
    activeTheme: slugTheme ?? themeBrowser ?? theme,
  }
}