import type { PropsWithClassName } from "~/types/react"
import { ThemeChangeListener } from "./ThemeChangeListener"
import ThemeSelect from "./ThemeSelect"

type ThemeSwitcherProps = PropsWithClassName<{

}>
export default function ThemeSwitcher({
  className,
  ...props
}: ThemeSwitcherProps) {

  const comp = <>
    {/* <ThemeToggleButton /> */}
    <ThemeSelect tooltipOri="left" />
    <ThemeChangeListener />
  </>

  return className === undefined ? comp : <div className={className}>{comp}  </div>

}