import { useCallback, useEffect } from "react";
import { defaultDarkTheme, defaultTheme, isDarkTheme } from "~/root";

export function ThemeChangeListener() {

  // useEffect(() => {
  //   window.matchMedia('(prefers-color-scheme: *)')
  //     .addEventListener('change', event => {
  //       const newColorScheme = event.matches ? "dark" : "light";
  //       console.log('newColorScheme', newColorScheme); //FIXME: REMOVE CLOG

  //     });
  // })

  function adaptTWDarkClassName(element: HTMLElement, darkThemeName = defaultDarkTheme) {
    const scheme = window.getComputedStyle(document.documentElement).colorScheme ?? 'light';
    if (scheme === 'dark') {
      element.classList.add('dark')
    } else {
      element.classList.remove('dark')
    }
  }

  const observeClassChange = useCallback((element: HTMLElement) => {
    // Select the node that will be observed for mutations
    const targetNode = element;
    const config: MutationObserverInit = {
      attributes: true,
      // subtree: true,
    };

    // Callback function to execute when mutations are observed
    const callback: MutationCallback = (mutationList, observer) => {
      for (const mutation of mutationList) {

        if (mutation.type === "attributes") {
          if (mutation.attributeName && mutation.attributeName.includes('data-theme')) {
            adaptTWDarkClassName(element);
          }
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
    // Trigger once
    adaptTWDarkClassName(element);
    // Later, you can stop observing
    return () => {
      observer.disconnect()
    };
  }, [])

  useEffect(() => {
    return observeClassChange(document.documentElement);
  }, [observeClassChange])


  return null;

}